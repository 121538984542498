PageItEditor = {};

PageItEditor.toolbar = '';
PageItEditor.toolbarGroups = [];
PageItEditor.customPlugins = [];
PageItEditor.colorMap = [
	"000000", "Schwarz",
	"424242", "Grau (dunkel)",
	"9e9e9e", "Grau",
	"FFFFFF", "Weiß",
	"b71c1c", "Rot",
	"f44336", "Rot (hell)",
	"0d47a1", "Blau",
	"2196f3", "Blau (hell)",
	"1b5e20", "Grün",
	"4caf50", "Grün (hell)",
	"e65100", "Orange",
	"ff6f00", "Orange (hell)",
	"fdd835", "Gelb",
	"ffee58", "Gelb (hell)",
	"9c27b0", "Violett",
	"e91e63", "Pink",
	"indigo", "Indigo",
	"00bcd4", "Cyan"
]

PageItEditor.addToolbarGroup = function(entry) {
	if (Array.isArray(entry)) {
		PageItEditor.toolbarGroups.push(entry);
	} else {
		PageItEditor.toolbarGroups.push([entry]);
	}
	
	PageItEditor.toolbar = '';
	var groups = [];
	for (var i = 0; i < PageItEditor.toolbarGroups.length; ++i) {
		groups.push(PageItEditor.toolbarGroups[i].join(" "));
	}
	
	PageItEditor.toolbar = groups.join(" | ");
}

PageItEditor.options = {
	selector: '.ck',
	height: 500,			
	relative_urls : true,
	valid_elements : '*[*]',
	content_css: [
		'https://fonts.googleapis.com/css?family=Lato:400,700',
		'https://fonts.googleapis.com/css?family=Source+Sans+Pro%3A300%2C400%2C700%2C300italic%2C400italic%2C700italic%7CBitter%3A400%2C700&subset=latin%2Clatin-ext',
		'https://fonts.googleapis.com/icon?family=Material+Icons',
		'api/admin/editorcss',
		'materialize/materialize.css'				
	],
	body_class: "editor-body",
	document_base_url : "/",
	convert_urls : false,
	toolbar: [
		'undo redo | formatselect forecolor blockquote | bold italic underline strikethrough | alignleft aligncenter alignright alignjustify | visualblocks visualchars fullscreen piCode',
		'hr | bullist numlist outdent indent | table',
		PageItEditor.toolbar
	],
	menubar: false,
	menu: false,
	plugins: [
		'advlist autolink link image lists charmap preview hr anchor pagebreak',
		'searchreplace wordcount visualblocks visualchars code fullscreen insertdatetime media nonbreaking',
		'table directionality paste',
		PageItEditor.customPlugins.join(" ")
	],
	color_map: PageItEditor.colorMap,
	automatic_uploads: true,
	paste_data_images: true,
	images_upload_url: "admin/pictures/api/autoupload",
	images_upload_handler: function(blob, success, failure) {
		console.log("UPLOAD");
		var formdata = new FormData();
		var dlg = new Dialog();
		dlg.loading("Bitte warten", "Bild wird hochgeladen...");
		formdata.append("upload", blob.blob(), blob.filename());
		$.ajax({
			url: 'admin/pictures/api/autoupload',
			method: "POST",
			data: formdata,
			processData: false,
			contentType: false,
			success: function(resp) {
				dlg.close();
				new Notification().success("Bild wurde hochgeladen");
				var img = $(tinyMCE.activeEditor.getBody()).find('img[src*="blob:"]:not(.pi_image)');
				//success(resp.picture.medium);								
				var opts = {
					src: resp.picture.medium,
					class: "pi_image pi_image_open",
					style: [],
					align: "",
					open: true,
					insert: true,
					toParallax: false
				};
				$(img).replaceWith(PiImage.setImage(img, resp.picture, opts));
			},
			error: function() {
				dlg.close();
				new Notification().error("Fehler beim hochgeladen");
				failure("Fehler beim Upload");
				var img = $(tinyMCE.activeEditor.getBody()).find('img[src*="blob:"]').remove();
			},
		});
	}
};